<template>
  <div>
    <Header />
    <div class="bg">
      <div class="content">
        <div class="left">
          <h3>也在币</h3>
          <p class="title">也在网的通用虚拟货币</p>
          <div class="yezai-surplus">
            <p class="first">你的剩余也在币</p>
            <p class="yezaiB">0 <span>个</span></p>
            <p style="font-size: 16px; color: #fff">可用于:</p>
            <div class="list">
              <div class="image">
                <div>
                  <img src="../../assets/images/gold/luzhi.png" alt="" />
                </div>
                人脸认证
              </div>
              <div class="image">
                <img src="../../assets/images/gold/courier.png" alt="" />
                也在信使
              </div>
              <div class="image">
                <img src="../../assets/images/gold/liwu.png" alt="" /> 礼物
              </div>
            </div>
            <p style="font-size: 16px; color: #fff">等各类在线征婚服务</p>
          </div>
        </div>
        <div class="centre">
          <p class="buy">购买也在币</p>
          <p class="num">充值数量:</p>
          <!-- 选择 -->
          <ul class="select">
            <li
              class="btn-select"
              v-for="(item, index) in items"
              :key="index"
              @click="changeValue(index)"
              :class="{ active: amountindex == index }"
            >
              <!-- <img src="../../assets/images/gold/jinbi.png" alt="" /> -->
              <img :src="item.img" alt="" />
              <p>{{ item.price }}</p>
            </li>
          </ul>
          <div class="select"></div>
          <div class="open">立即开通</div>
        </div>
        <!-- 服务说明 -->
        <div class="right">
          <p>服务说明</p>
          <div>
            1、也在币为也在网专用虚拟货 币，可以购买部分特色自助服务
            (包括进行认证、礼物、进行直 播)。<br />
            2、也在币仅限也在网用户本人使用，不得转让给他人; <br />
            3、也在币属于虚拟货币，不可退 费，请你酌情购买;<br />
            4、购买的也在币自购买之日起， 半年内有效，所有也在币的有效
            期，以最后一次充值时间为准; 快过期时，你可以通过继续充值
            来延长有效期。
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/layout/footer";
import Header from "@/components/layout/header";
export default {
  data() {
    return {
      amountindex: 0,
      items: [
        { price: "6个/¥12", img: require("@/assets/images/gold/jinbi.png") },
        { price: "15个/¥30", img: require("@/assets/images/gold/jinbi-2.png") },
        { price: "50个/¥98", img: require("@/assets/images/gold/jinbi-3.png") },
        {
          price: "150个/¥278",
          img: require("@/assets/images/gold/jinbi-5.png"),
        },
        {
          price: "500个/¥898",
          img: require("@/assets/images/gold/jinbi-4.png"),
        },
      ],
    };
  },
  components: {
    Footer,
    Header,
  },
  methods: {
    changeValue(index) {
      this.amountindex = index;
      console.log("sss", index);
    },
  },
};
</script>

<style lang="less" scoped>
.bg {
  background: url("../../assets/images/slices/bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  // height: 1350px;
  height: 800px;
  display: flex;
  justify-content: center;
  @media screen {
    min-width: 1400px;
  }
  .content {
    width: 100%;
    height: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 150px;
    .right {
      // width: 296px;
      // height: 720px;
      width: 278px;
      height: 570px;
      background: #ffffff;
      box-sizing: border-box;
      border: 1px solid #fff;
      border-radius: 4px;
      padding-left: 24px;
      padding-right: 24px;
      margin-left: 40px;
      p {
        height: 48px;
        font-size: 20px;
        font-weight: bold;
        color: #555555;
        margin-bottom: 15px;
        margin-top: 30px;
        border-bottom: 1px solid #ececec;
      }
      div {
        font-size: 14px;
        color: #555555;
        line-height: 36px;
      }
    }

    .centre {
      // width: 688px;
      // height: 720px;
      width: 570px;
      height: 570px;
      background: #fff;
      border: 1px solid #fff;
      box-sizing: border-box;
      border-radius: 4px;
      margin-left: 46px;
      // padding-left: 50px;
      padding-left: 30px;
      padding-right: 30px;
      .buy {
        // width: 600px;
        // height: 98px;
        border-bottom: 1px solid #ececec;
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        // line-height: 98px;
        line-height: 74px;
      }
      .num {
        font-size: 24px;
        color: #666666;

        margin-top: 15px;
        margin-bottom: 20px;
      }
      .open {
        width: 212px;
        height: 60px;
        background: linear-gradient(270deg, #ffaf2d 0%, #ffc74b 100%);
        border-radius: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        margin: 0 auto;
        cursor: pointer;
      }

      .select {
        width: 600px;
        height: 180px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .btn-select:hover {
          background: #fff7e0;
          box-sizing: border-box;
        }
        .btn-select {
          width: 150px;
          height: 150px;
          background: #f7f7f7;
          box-sizing: border-box;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 30px;
          margin-bottom: 20px;
          img {
            margin: 36px 0 28px 0;
          }
          p {
            font-size: 16px;
            color: #555555;
            line-height: 24px;
          }
        }
        .btn-select:nth-child(1),
        .btn-select:nth-child(4) {
          margin-left: 0px;
        }
        .active {
          // width: 162px;
          // height: 162px;
          border: 4px solid #f8ef98;
          box-sizing: border-box;
          background: #fff7e0;
        }
      }
    }
    .left {
      // width: 338px;
      // height: 720px;
      h3 {
        font-size: 46px;
        // font-family: PingFangSC-Medium, PingFang SC;
        // font-weight: 500;
        color: #ffffff;
        // line-height: 78px;
      }
      .title {
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6);
        // line-height: 45px;
        margin-top: 15px;
        margin-bottom: 30px;
      }
      .yezai-surplus {
        width: 320px;
        height: 560px;
        // background: #ffffff;
        border-radius: 4px;

        box-sizing: border-box;
        // padding-left: 31px;
        // padding-top: 36px;
        // padding-right: 9px;
        .first {
          font-size: 16px;
          color: #fff;
          margin-bottom: 30px;
        }
        .yezaiB {
          width: 280px;
          height: 120px;
          font-size: 60px;
          // font-weight: bold;
          color: #fff;
          // line-height: 120px;
          // text-align: center;
          // border-bottom: 2px solid #ececec;
          // margin: 0 auto;
          // padding-bottom: 10px;
          // margin-bottom: 20px;
          span {
            font-size: 40px;
            color: #fff;
            // line-height: 112px;
          }
        }
        .list {
          height: 194px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .image {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #fff;
            img {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
